<template>
  <b-modal
      id="acquiringHintModal"
      title="Доступні термінали"
      ok-only
  >
    <p id="terminal">Для поповнення використовуйте номер особового рахунку: <b>{{ getCashlessTerminalId() }}</b></p>
    <b-button @click="copyToClipboard" class="mb-1">Скопіювати номер рахунку</b-button>
    <p>Вам доступні наступні термінали:</p>
    <p v-if="availableTerminals"><b>{{ availableTerminals.join(", ") }}</b></p>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
import { useAcquiringProviders } from '@/composition/acquiringProviders/useAcquiringProviders';
import { useBusinessTransactions } from '@/composition/businessTransactions/useBusinessTransactions';

export default {
  name: 'AcquiringHintModal',
  components: {
    BModal,
    BButton,
  },
  methods: {
    copyToClipboard() {
      const element = document.querySelector('#terminal');
      const cashlessTerminalId = this.getCashlessTerminalId();
      
      const store = document.createElement('textarea');
      store.value = cashlessTerminalId;
      element.appendChild(store);
      store.select();
      document.execCommand('copy');
      element.removeChild(store);
    }
  },
  setup() {
    const { availableTerminals } = useAcquiringProviders();
    const { getCashlessTerminalId } = useBusinessTransactions();

    return {
      availableTerminals,
      getCashlessTerminalId,
    };
  }
};
</script>

<style scoped>

</style>
