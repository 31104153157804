<template>
  <b-list-group class="my-1">
    <b-list-group-item
      v-for="card in cards"
      :key="card.id"
      :active="card.id === value"
      button
      class="d-flex justify-content-between align-items-center"
      @click="setCard(card.id)"
    >
      <span>{{ card.mask ? card.mask : "Маска відсутня" }}</span>
      <feather-icon
          v-if="card.id === value"
          icon="CheckIcon"
          size="24"
      />

    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";

export default {
  name: "AvailableCards",

  components: {
    BListGroup,
    BListGroupItem,
  },

  props: {
    cards: {
      type: Array,
      required: true,
    },
    value: {
      type: String | Number,
      default: "",
    },
  },

  emits: ['input'],

  setup(props, { emit }) {
    const setCard = (card) => {
      card === props.value ? emit("input", "") : emit("input", card);
    }

    return {
      setCard,
    }
  }
}
</script>