<template>
  <b-card header="Оберіть рахунок:">
    <AcquiringModal />
    <AcquiringHintModal />
    <b-list-group>
      <b-skeleton-wrapper :loading="walletLoading">
        <template #loading>
          <b-skeleton width="85%" />
        </template>
        <b-list-group-item
            v-for="balance in filteredWallet"
            :key="balance.cashType"
        >
          <span>{{ `${getAccountsTypesTitle(balance.cashType)} - ${getWalletBalance(balance.cashType)} грн` }}</span>
          <b-button
              v-if="checkAllowedAccountTypes(balance.cashType)"
              v-b-modal.acquiringModal
              class="acquiring-button mx-1"
              variant="primary"
              size="sm"
          >
            Поповнити карткою
          </b-button>
          <b-button
              v-if="checkAllowedAccountTypes(balance.cashType)"
              v-b-modal.acquiringHintModal
              class="acquiring-button mx-1"
              variant="primary"
              size="sm"
          >
            Поповнити через термiнал
          </b-button>
          <b-button
            v-if="!checkAllowedAccountTypes(balance.cashType)"
            class="acquiring-button mx-1"
            variant="primary"
            to="/apps/invoice/list"
            size="sm"
          >
            Інвойси
          </b-button>
        </b-list-group-item>
      </b-skeleton-wrapper>
    </b-list-group>
  </b-card>
</template>

<script>
import { BListGroup, BListGroupItem, BCard, BSkeleton, BSkeletonWrapper, BButton } from "bootstrap-vue";
import AcquiringModal from "@/views/apps/acquiring/AcquiringModal.vue";
import AcquiringHintModal from "@/views/apps/acquiring/AcquiringHintModal.vue";

import { useBusinessTransactions } from "@/composition/businessTransactions/useBusinessTransactions";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import {useBusinessSettings} from "@/composition/businessSettings/useBusinessSettings";

const TYPE_ACCOUNT_CASH = 1;
const TYPE_ACCOUNT_CASHLESS = 2;

const TYPE_ACQUIRING_ALLOWED = [
    TYPE_ACCOUNT_CASH,
];

export default {
  name: "AccountsForAcquiring",

  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BSkeleton,
    BSkeletonWrapper,
    BButton,
    AcquiringModal,
    AcquiringHintModal,
  },
  methods: {
    checkAllowedAccountTypes(needleType) {
      if (!this.accountTypes) {
        return false;
      }

      const isAllowed = this.accountTypes.filter(type => type === needleType).length === 1;

      if (!isAllowed) {
        return false;
      }

      if (!TYPE_ACQUIRING_ALLOWED.includes(needleType)) {
        return false;
      }

      return true;
    },
  },
  computed: {
    filteredWallet() {
      return this.wallet.filter(balance => this.accountTypes.includes(balance.cashType));
    }
  },
  setup() {
    const { wallet, walletLoading, getWalletBalance } = useBusinessTransactions();
    const { getAccountsTypesTitle } = useAccountsTypes()
    const { accountTypes } = useBusinessSettings();

    return {
      wallet,
      walletLoading,
      getWalletBalance,
      getAccountsTypesTitle,
      accountTypes,
    };
  }
}
</script>
<style lang="scss">
  @media screen and (max-width: 768px) {
    .list-group-item {
      display: flex;
      flex-direction: column;
    }
    .acquiring-button {
      max-width: 50%;
      margin: 0.5rem 0 0 0 !important;
    }
  }
</style>
