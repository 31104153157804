import {computed, onBeforeMount, reactive, toRefs} from "@vue/composition-api";
import AcquiringService from "@/services/acquiring/acquiring.service";

import { acquiringStoreModule } from "@/store/acquiring/acquiringStrore.module";
import { useStoreModule } from "@/composition/useStoreModule";
import store from "@/store";

const STORE_MODULE_NAME = "acquiringModule";

export function useAcquiringProviders() {

    useStoreModule(STORE_MODULE_NAME, acquiringStoreModule);

    const data = reactive({
        acquiringTerminals: null,
        paymentContextLoading: false,
    })

    const postPaymentContext = async (params) => {
        data.paymentContextLoading = true
        try {
            const response = await AcquiringService.postPaymentContext(params)
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        } finally {
            data.paymentContextLoading = false;
        }
    }

    const fetchTerminalConfig = async () => {
        try {
            const response = await AcquiringService.getTerminalConfig();
            data.acquiringTerminals = response.data.data;
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    const availableTerminals = computed(() => {
        return data.acquiringTerminals?.paymentMethods.items.map(i => i.title)
    });

    const acquiringProvidersLoading = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_PAYMENT_CONFIG_LOADING`]
    });

    const cards = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_PAYMENT_CONFIG_CARDS`]
    });

    const acquiringProviders = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_PAYMENT_CONFIG_PROVIDERS`]
    });

    onBeforeMount(async() => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchAcquiringProviders`)
        await fetchTerminalConfig();
    });

    return {
        acquiringProvidersLoading,
        cards,
        acquiringProviders,
        ...toRefs(data),
        postPaymentContext,
        availableTerminals,
    }
}
