<template>
  <div>
    <b-row>
      <b-col>
        <AllowedAccounts />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <AccountsForAcquiring />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BCard, BRow, BCol } from "bootstrap-vue";
import AllowedAccounts from "@/views/apps/acquiring/AllowedAccounts.vue";
import AccountsForAcquiring from "@/views/apps/acquiring/AccountsForAcquiring.vue";

export default {
  name: "AcquiringView",

  components: {
    BCard,
    BRow,
    BCol,
    AllowedAccounts,
    AccountsForAcquiring,
  },
}
</script>
