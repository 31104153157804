<template>
  <b-card>
    <b-form-group
        label="Доступні види рахунків:"
        label-for="account-username"
        label-cols="4"
        label-cols-lg="4"
        class="account-types" 
    >
      <b-form-checkbox-group
          :checked="accountTypes"
          :options="accountsTypes"
          value-field="value"
          text-field="translated"
          class="account-item"
          disabled
      ></b-form-checkbox-group>
    </b-form-group>
  </b-card>
</template>

<script>

import { BFormGroup, BFormCheckboxGroup, BCard } from "bootstrap-vue";

import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";
import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";

export default {
  name: "AllowedAccounts",

  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BCard
  },

  setup() {
    const { accountTypes } = useBusinessSettings();
    const { accountsTypes } = useAccountsTypes();

    return {
      accountTypes,
      accountsTypes,
    };
  }
}
</script>
<style type="scss">
  .account-types {
    display: flex;
    align-items: center;
  }
  .account-item {
    @media screen and (max-width: 768px) {
        /*margin-bottom: 1rem;*/
      }
  }
</style>
