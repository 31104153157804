import BusinessTransactionsService from "@/services/businessTransactions/businessTransactions.service";

export const transactionsStoreModule = {
    namespaced: true,

    state: {
        wallet: null,
        walletLoading: false,
    },

    actions: {
        async fetchCompanyBalances({ state, commit }) {
            commit("SET_WALLET_LOADING", true);

            if (state.wallet) {
                commit("SET_WALLET_LOADING", false);
            }

            try {
                const response = await BusinessTransactionsService.getCompanyBalances();
                commit("SET_WALLET", response.data.wallet);
            } catch (error) {
                return error
            } finally {
                commit("SET_WALLET_LOADING", false);
            }
        }
    },

    mutations: {
        SET_WALLET(state, data) {
            state.wallet = data;
        },
        SET_WALLET_LOADING(state, loading) {
            state.walletLoading = loading;
        },
    },

    getters: {
        GET_WALLET(state) {
            return state.wallet;
        },
        GET_WALLET_LOADING(state) {
            return state.walletLoading;
        },
        GET_CASHLESS_WALLET(state) {
            return state.wallet?.find(wallet => wallet.cashType === 1);
        },
    },
};
