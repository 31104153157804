import AcquiringService from "@/services/acquiring/acquiring.service";

export const acquiringStoreModule = {
    namespaced: true,

    state: {
        paymentConfig: null,
        acquiringTerminals: null,
        paymentConfigLoading: false,
        paymentContextLoading: false,
    },

    actions: {
        async fetchAcquiringProviders({ state, commit }) {
            commit("SET_PAYMENT_CONFIG_LOADING", true);

            if (state.acquiringProviders) {
                commit("SET_PAYMENT_CONFIG_LOADING", false);
            }

            try {
                const response = await AcquiringService.getPaymentConfiguration();
                commit("SET_PAYMENT_CONFIG", response.data.data);
            } catch (error) {
                return error
            } finally {
                commit("SET_PAYMENT_CONFIG_LOADING", false);
            }
        }
    },

    mutations: {
        SET_PAYMENT_CONFIG(state, data) {
            state.paymentConfig = data;
        },
        SET_CARDS(state, data) {
            state.cards = data
        },
        SET_PAYMENT_CONFIG_LOADING(state, loading) {
            state.paymentConfigLoading = loading;
        },
    },

    getters: {
        GET_PAYMENT_CONFIG_PROVIDERS(state) {
            return state.paymentConfig.paymentMethods.items;
        },
        GET_PAYMENT_CONFIG_CARDS(state) {
            return state.paymentConfig.cards;
        },
        GET_PAYMENT_CONFIG_LOADING(state) {
            return state.paymentConfigLoading;
        },
    },
};
