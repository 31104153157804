import { onBeforeMount, computed} from "@vue/composition-api";
import { transactionsStoreModule } from "@/store/businessTransactions/businessTransactionsStore.module";
import { useStoreModule } from "@/composition/useStoreModule";
import store from "@/store";

const STORE_MODULE_NAME = "transactionsModule";

export function useBusinessTransactions() {

    useStoreModule(STORE_MODULE_NAME, transactionsStoreModule);

    function getWalletBalance(type) {
        const balance = store.getters[`${STORE_MODULE_NAME}/GET_WALLET`]?.find(balance => balance.cashType === type).balance
        return (balance / 100).toFixed(2)
    }

    const getCashlessTerminalId = () => {
        return store.getters[`${STORE_MODULE_NAME}/GET_CASHLESS_WALLET`]?.accountTerminalId;
    };

    const wallet = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_WALLET`]
    });

    const walletLoading = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_WALLET_LOADING`];
    });

    onBeforeMount(async () => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchCompanyBalances`)
    });

    return {
        wallet,
        walletLoading,
        getWalletBalance,
        getCashlessTerminalId,
    };
}
